import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { HashRouter, Route } from "react-router-dom";
import Login from './Login';
import MapScreen from './MapScreen';
import AdminGeocercas from './geocercas/AdminGeocercas';
import Policias from './policias/Policias';
import Usuarios from './Usuarios';
import AppContext from './AppContext';

class App extends Component {
  getCookie = (name) => {
    const { cookies } = this.props;
    return cookies.get(name);
  };

  setCookie = (name, value) => {
    const { cookies } = this.props;
    cookies.set(name, value, { path: '/' });
  };

  removeCookie = (name) => {
    const { cookies } = this.props;
    cookies.remove(name);
  }

  render() {
    return (
        <div className="App">
          <HashRouter>
            <div>
              <AppContext.Provider
                value={{
                  setCookie: this.setCookie,
                  getCookie: this.getCookie,
                  removeCookie: this.removeCookie,
                }}
              >
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Login
                      getCookie={this.getCookie}
                      setCookie={this.setCookie}
                    />
                  )}
                />
                <Route
                  path="/map"
                  render={() => (
                    <MapScreen
                      getCookie={this.getCookie}
                      setCookie={this.setCookie}
                      removeCookie={this.removeCookie}
                    />
                  )}
                />
                <Route
                  path="/zonas"
                  render={() => (
                    <AdminGeocercas
                      getCookie={this.getCookie}
                      setCookie={this.setCookie}
                      removeCookie={this.removeCookie}
                    />
                  )}
                />
                <Route
                  path="/policias"
                  render={() => (
                    <Policias
                      getCookie={this.getCookie}
                      setCookie={this.setCookie}
                      removeCookie={this.removeCookie}
                    />
                  )}
                />
                <Route
                  path="/usuarios"
                  render={() => (
                    <Usuarios
                      getCookie={this.getCookie}
                      setCookie={this.setCookie}
                      removeCookie={this.removeCookie}
                    />
                  )}
                />
              </AppContext.Provider>
            </div>
          </HashRouter>
        </div>
    );
  }
}

export default withCookies(App);
