import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, Redirect } from "react-router-dom";
import request from 'request-promise';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import ExitIcon from '@material-ui/icons/ExitToApp';
import BubbleChart from '@material-ui/icons/BubbleChart';
import Person from '@material-ui/icons/Person';
import Security from '@material-ui/icons/Security';
import Home from '@material-ui/icons/Home';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import AppContext from './AppContext';

import SECRETS from './secrets';

import { subscribePush, unsubscribePush, getSubscriptionId } from './registerPushServiceWorker';

const styles = (theme) =>  ({
  drawer: {
    width: '200px',
  },
  drawerPaper: {
    width: '200px',
  },
  listItem: {
    cursor: 'pointer',
  },
});

class Menu extends Component {
  state = {
    logged: true,
    pushNotificationsActive: false,
  };

  componentDidMount = () => {
    getSubscriptionId()
      .then((subscriptionId) => {
        if (subscriptionId) {
          this.setState({ pushNotificationsActive: true });
        }
      });
  };

  logout = () => {
    const { removeCookie } = this.context;
    removeCookie('token');
    this.setState({ logged: false });
  };

  handleUserPushNotifications = (event) => {
    const { getCookie } = this.context;
    const pushNotificationsActive = event.target.checked;
    console.log('click');
    console.log(pushNotificationsActive);
    if (pushNotificationsActive) {
      subscribePush()
        .then((pushSubscription) => {
          const subscription = {
            webtoken: JSON.stringify(pushSubscription.subscription),
          };
          console.log(subscription);

          request({
            method: 'POST',
            uri: `${SECRETS.serverUrl}/notificacion`,
            body: subscription,
            json: true,
            headers: {
              'Authorization': getCookie('token'),
            },
          })
            .then(() => {
              // TODO: update user preference pushNotifications
              this.setState({ pushNotificationsActive });
              // setProcessInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              if (error.statusCode === 403) {
                this.logout();
              }
            });
        })
        .catch((error) => {
          console.error('Unable to subscribe to push.', error);
          // setProcessInProgress(false);
        });
    } else {
      getSubscriptionId()
        .then((subscriptionId) => {
          request({
            method: 'POST',
            uri: `${SECRETS.serverUrl}/notificacion`,
            body: {
              webtoken: '',
            },
            json: true,
            headers: {
              'Authorization': getCookie('token'),
            },
          })
            .then((deleted) => {
              if (deleted) {
                unsubscribePush()
                  .then(() => {
                    this.setState({ pushNotificationsActive });
                    // setProcessInProgress(false);
                  });
              }
              // setProcessInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              // setProcessInProgress(false);
              if (error.statusCode === 403) {
                this.logout();
              }
            });
        });
    }
  };

  render = () => {
    const { classes } = this.props;
    const { logged, pushNotificationsActive } = this.state;
    if (!logged) return <Redirect to="/" />;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List>
          <ListItem button component={Link} to={`/map`} className={classes.listItem}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary={'Inicio'}/>
          </ListItem>
          <ListItem button component={Link} to={`/usuarios`} className={classes.listItem}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary={'Usuarios'}/>
          </ListItem>
          <ListItem button component={Link} to={`/policias`} className={classes.listItem}>
            <ListItemIcon>
              <Security />
            </ListItemIcon>
            <ListItemText primary={'Policias'}/>
          </ListItem>
          <ListItem button component={Link} to={`/zonas`} className={classes.listItem}>
            <ListItemIcon>
              <BubbleChart />
            </ListItemIcon>
            <ListItemText primary={'Zonas'}/>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <NotificationImportantIcon />
            </ListItemIcon>
            <ListItemText primary="Notificaciones" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.handleUserPushNotifications}
                checked={pushNotificationsActive}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem onClick={this.logout} className={classes.listItem}>
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText primary={'Salir'}/>
          </ListItem>
        </List>
      </Drawer>
    );
  };
}

Menu.contextType = AppContext;

export default withStyles(styles)(Menu);
