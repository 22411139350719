import React from 'react';

import CustomMarker from './CustomMarker';


const MarkerUnidad = ({
  point,
  handleClick,
  enablePanorama,
  setPanorama,
}) => (
  <CustomMarker
    id={point.id}
    position={{ lat: point.latitud, lng: point.longitud }}
    info={() => {}}
    label={point.usuario.nombres}
    motor={point.inZona}
  >
  </CustomMarker>
);

export default MarkerUnidad;
