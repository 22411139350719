import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import request from 'request-promise';

import Menu from '../Menu';

import SECRETS from '../secrets';


const styles = (theme) =>  ({
  geocercasScreen: {
    display: 'flex',
    flexDirection: 'row',
  },
  view: {
    width: '100%',
  },
  newView: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  map: {
    height: '85vh',
  },
  actionButton: {
    position: 'fixed',
    bottom: 30,
    right: 30,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  header: {
    backgroundColor: '#333',
  },
  whiteText: {
    color: 'white',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: '80px',
  },
  action: {
    margin: '0 10px',
  },
  cell: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  newForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '98vh',
  },
  newTextField: {
    width: 600,
  },
  button: {
    marginTop: 30,
  },
  selectField: {
    marginTop: 30,
  },
});


class Policias extends Component {
  state = {
    policias: [],
    zonas: [],
    showNewForm: false,
    newPolicia: {
      user: '',
      password: '',
      repassword: '',
      emails: '',
      nombres: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      zona: 0,
    },
  };

  componentDidMount = () => {
    this.getPolicias();
    this.getZonas();
  };

  getZonas = () => {
    const { getCookie } = this.props;
    request({
      method: 'GET',
      uri: `${SECRETS.serverUrl}/Zona`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        this.setState({ zonas: resp.data });
      })
      .catch(err => console.error(err));
  };

  getPolicias = () => {
    const { getCookie } = this.props;
    request({
      method: 'GET',
      uri: `${SECRETS.serverUrl}/policia`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const zona = { nombre: '', id: 0 };
        const policias = resp.data.map((policia) => {
          if (policia.zona === null) {
            policia.zona = zona;
          }
          policia.edit = false;
          policia.usuario.password = '******';
          return policia;
        });
        this.setState({ policias });
      })
      .catch(err => console.error(err));
  };

  updateUsuario = (index) => {
    const { getCookie } = this.props;
    const { policias } = this.state;
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      user,
      password,
      emails,
      id,
    } = policias[index].usuario;
    const attribs = {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      user,
      emails,
      id,
    };
    if (password !== '******' && password.length > 6) {
      attribs.password = password;
    }
    request({
      method: 'PATCH',
      uri: `${SECRETS.serverUrl}/usuario/${id}`,
      body: attribs,
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      },
    })
      .then((resp) => {

      })
      .catch(err => console.error(err));
  };

  updatePolicia = (index) => {
    const { getCookie } = this.props;
    const { policias } = this.state;
    const { zona, id } = policias[index];
    this.updateUsuario(index);
    if (zona.id > 0) {
      request({
        method: 'PATCH',
        uri: `${SECRETS.serverUrl}/policia/${id}`,
        body: {
          zona: zona.id,
        },
        json: true,
        headers: {
          'Authorization': getCookie('token'),
        }
      })
      .then((resp) => {
        policias[index].edit = false;
        this.setState({ policias });
      })
      .catch(err => console.error(err));
    }
  };

  eliminarPolicia = (id) => () => {
    const { getCookie } = this.props;
    const { policias } = this.state;
    request({
      method: 'DELETE',
      uri: `${SECRETS.serverUrl}/policia/${id}`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const index = policias.findIndex(policia => policia.id === id);
        policias.splice(index, 1);
        this.setState({ policias });
      })
      .catch(err => console.error(err));
  };

  activarEdicion = (index) => () => {
    const { policias } = this.state;
    policias[index].edit = true;
    this.setState({ policias });
  };

  guardarEdicion = (index) => () => {
    this.updatePolicia(index);
  };

  guardarNuevoPolicia = () => {
    const { getCookie } = this.props;
    const { newPolicia, policias } = this.state;
    if (
      newPolicia.user.length < 3
      && newPolicia.password.length < 6
      && newPolicia.password !== newPolicia.repassword
      && newPolicia.nombres.length < 3
      && newPolicia.apellidoPaterno.length < 3
      && newPolicia.apellidoMaterno.length < 3
      && newPolicia.zona < 1
    ) {
      return 'error';
    }
    request({
      method: 'POST',
      uri: `${SECRETS.serverUrl}/usuario`,
      body: {
        user: newPolicia.user,
        password: newPolicia.password,
        emails: newPolicia.emails,
        nombres: newPolicia.nombres,
        apellidoPaterno: newPolicia.apellidoPaterno,
        apellidoMaterno: newPolicia.apellidoMaterno,
        isPolicia: true,
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
    .then((respusuario) => {
      console.log(respusuario);
      request({
        method: 'POST',
        uri: `${SECRETS.serverUrl}/policia`,
        body: {
          usuario: respusuario.id,
          zona: newPolicia.zona,
        },
        json: true,
        headers: {
          'Authorization': getCookie('token'),
        }
      })
      .then((resppolicia) => {
        console.log('usuario creado');
        policias.push({
          id: resppolicia.id,
          uuid: resppolicia.uuid,
          zona: {
            id: newPolicia.zona,
          },
          usuario: {
            user: newPolicia.user,
            password: '******',
            emails: newPolicia.emails,
            nombres: newPolicia.nombres,
            apellidoPaterno: newPolicia.apellidoPaterno,
            apellidoMaterno: newPolicia.apellidoMaterno,
          },
        });
        const newPoliciaReset = {
          user: '',
          password: '',
          repassword: '',
          emails: '',
          nombres: '',
          apellidoPaterno: '',
          apellidoMaterno: '',
          zona: 0,
        };
        this.setState({
          newPolicia: newPoliciaReset,
          policias,
          showNewForm: false,
        });
      })
      .catch(err => console.error(err));
    })
    .catch(err => console.error(err));
  };

  toggleNew = () => {
    this.setState({ showNewForm: true });
  };

  handleChange = (name, index) => (ev) => {
    const { policias } = this.state;
    policias[index].usuario[name] = ev.target.value;
    this.setState({ policias });
  };

  handleChangeNew = (name) => (ev) => {
    const { newPolicia } = this.state;
    newPolicia[name] = ev.target.value;
    this.setState({ newPolicia });
  };

  handleChangeZona = (index) => (ev) => {
    const { policias } = this.state;
    policias[index].zona.id = ev.target.value;
    this.setState({ policias });
  };

  handleChangeNewZona = (ev) => {
    const { newPolicia } = this.state;
    newPolicia.zona = ev.target.value;
    this.setState({ newPolicia });
  };


  render = () => {
    const { classes, getCookie, removeCookie } = this.props;
    const {
      policias,
      zonas,
      showNewForm,
      newPolicia,
    } = this.state;
    if (!getCookie('token')) return <Redirect to="/" />;
    return (
      <div className={classes.geocercasScreen}>
        <Menu removeCookie={removeCookie}/>
        <div className={classes.view}>
          {!showNewForm && (
            <div>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell align="left" className={classes.whiteText}>Usuario</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Contraseña</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Correos</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Nombres</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Apellido Paterno</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Apellido Materno</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Zona Asignada</TableCell>
                      <TableCell align="left" className={classes.whiteText}>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {policias.map((policia, index) => (
                    <TableRow key={policia.id}>
                      <TableCell align="left"  className={classes.cell}>
                        <TextField
                        id={`user${policia.id}`}
                        className={classes.textField}
                        value={policia.usuario.user}
                        onChange={this.handleChange('user', index)}
                        margin="normal"
                        disabled={!policia.edit}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        <TextField
                        id={`password${policia.id}`}
                        className={classes.textField}
                        type="password"
                        value={policia.usuario.password}
                        onChange={this.handleChange('password', index)}
                        margin="normal"
                        disabled={!policia.edit}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        <TextField
                        id={`correos${policia.id}`}
                        className={classes.textField}
                        value={policia.usuario.emails || ''}
                        onChange={this.handleChange('emails', index)}
                        margin="normal"
                        disabled={!policia.edit}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        <TextField
                        id={`nombre${policia.id}`}
                        className={classes.textField}
                        value={policia.usuario.nombres}
                        onChange={this.handleChange('nombres', index)}
                        margin="normal"
                        disabled={!policia.edit}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        <TextField
                        id={`apellidoP${policia.id}`}
                        className={classes.textField}
                        value={policia.usuario.apellidoPaterno}
                        onChange={this.handleChange('apellidoPaterno', index)}
                        margin="normal"
                        disabled={!policia.edit}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        <TextField
                        id={`apellidoM${policia.id}`}
                        className={classes.textField}
                        value={policia.usuario.apellidoMaterno || ''}
                        onChange={this.handleChange('apellidoMaterno', index)}
                        margin="normal"
                        disabled={!policia.edit}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Select
                        value={policia.zona.id}
                        onChange={this.handleChangeZona(index)}
                        disabled={!policia.edit}
                        input={<Input name="zona" id={`zona${index}`} value={policia.zona} />}
                        >
                          <MenuItem value={0}>
                          Sin zona asignada
                          </MenuItem>
                          {zonas.map(zona => (
                            <MenuItem key={zona.id} value={zona.id}>{zona.nombre}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        {policia.edit && (
                          <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          onClick={this.guardarEdicion(index)}
                          >
                          Guardar
                          </Button>
                        )}
                        {!policia.edit && (
                          <div className={classes.actions}>
                          <EditIcon className={classes.action} onClick={this.activarEdicion(index)} />
                          <DeleteForever className={classes.action} onClick={this.eliminarPolicia(policia.id)} />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Paper>
              <div className={classes.actionButton}>
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.toggleNew}>
                  <AddIcon />
                </Fab>
              </div>
            </div>
          )}
          {showNewForm && (
            <div>
              <Paper className={classes.newForm}>
                <TextField
                  id={`newuser`}
                  className={classes.newTextField}
                  value={newPolicia.user}
                  label="usuario"
                  onChange={this.handleChangeNew('user')}
                  margin="normal"
                />
                <TextField
                  id={`newpassword`}
                  className={classes.newTextField}
                  value={newPolicia.password}
                  type="password"
                  label="contraseña"
                  onChange={this.handleChangeNew('password')}
                  margin="normal"
                />
                <TextField
                  id={`newrepassword`}
                  className={classes.newTextField}
                  value={newPolicia.repassword}
                  type="password"
                  label="repetir contraseña"
                  onChange={this.handleChangeNew('repassword')}
                  margin="normal"
                />
                <TextField
                  id={`newemails`}
                  className={classes.newTextField}
                  value={newPolicia.emails}
                  label="correos"
                  onChange={this.handleChangeNew('emails')}
                  margin="normal"
                />
                <TextField
                  id={`newnombres`}
                  className={classes.newTextField}
                  value={newPolicia.nombres}
                  label="nombres"
                  onChange={this.handleChangeNew('nombres')}
                  margin="normal"
                />
                <TextField
                  id={`newapellidoPaterno`}
                  className={classes.newTextField}
                  value={newPolicia.apellidoPaterno}
                  label="apellido paterno"
                  onChange={this.handleChangeNew('apellidoPaterno')}
                  margin="normal"
                />
                <TextField
                  id={`newapellidoMaterno`}
                  className={classes.newTextField}
                  value={newPolicia.apellidoMaterno}
                  label="apellido materno"
                  onChange={this.handleChangeNew('apellidoMaterno')}
                  margin="normal"
                />
                <Select
                  className={classes.selectField}
                  value={newPolicia.zona}
                  onChange={this.handleChangeNewZona}
                  input={<Input name="zona" id={`newzona`} value={newPolicia.zona} />}
                >
                  <MenuItem value={0}>
                    Sin zona asignada
                  </MenuItem>
                  {zonas.map(zona => (
                    <MenuItem key={zona.id} value={zona.id}>{zona.nombre}</MenuItem>
                  ))}
                </Select>
                <div className={classes.button}>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={this.guardarNuevoPolicia}
                  >
                    Guardar
                  </Button>
                </div>
              </Paper>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(Policias);
