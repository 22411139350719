import React, { Component } from 'react';
import { Polygon } from 'react-google-maps';

/*global google:true*/

class BayPolygon extends Component {
  state = {
    position: -1,
  };

  componentDidUpdate() {
    google.maps.event.addListener(
      this.ref.getPath(),
      'set_at',
      position => this.savePath(position)
    );

    google.maps.event.addListener(
      this.ref.getPath(),
      'insert_at',
      position => this.savePath(position, true)
    );
  }

  componentDidMount() {
    google.maps.event.addListener(
      this.ref.getPath(),
      'set_at',
      position => this.savePath(position)
    );

    google.maps.event.addListener(
      this.ref.getPath(),
      'insert_at',
      position => this.savePath(position, true)
    );
  }

  savePath = (position, inserted) => {
    const { index, polygonSet, pathSet } = this.props;
    const latlng = {
      lat: this.ref.getPath().j[position].lat(),
      lng: this.ref.getPath().j[position].lng(),
    };
    if (!inserted) {
      polygonSet(index, latlng, position, inserted);
    } else {
      const newPath = this.ref.getPath().j.map((p) => {
        return { lat: p.lat(), lng: p.lng() };
      });
      pathSet(index, newPath);
    }
  };

  bindRef = ref => this.ref = ref;

  render() {
    const {
      path,
      editable,
      visible,
      onRightClick
    } = this.props;
    return <Polygon
      ref={this.bindRef}
      path={path}
      editable={editable}
      visible={visible}
      onRightClick={onRightClick}
    />;
  }
}

export default BayPolygon;
