import React from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  // StreetViewPanorama,
  // Polyline,
  // Marker,
} from 'react-google-maps';

import BayPolygon from './BayPolygon';

import MarkerUnidad from './MarkerUnidad';


const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      ref={props.onMapMounted}
      onBoundsChanged={props.onBoundsChanged}
      defaultZoom={13}
      defaultCenter={{
        lat: 19.702864,
        lng: -101.192345,
      }}
    >
      <div>
        {props.geofences.map((geofence, index) => (
          <BayPolygon
            key={geofence.uuid}
            index={index}
            path={geofence.path}
            editable={geofence.edit}
            visible={geofence.active}
            getInitialPos={props.getInitialPos}
            refMap={props.refMap}
            polygonSet={props.polygonSet}
            pathSet={props.pathSet}
            onRightClick={props.toggleEdit(index)}
          />
        ))}
        {props.policias.map(policia => (
          <MarkerUnidad
            point={policia}
            key={policia.id}
          />
        ))}
      </div>
    </GoogleMap>
  )),
);

export default Map;
