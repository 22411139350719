import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import request from 'request-promise';

import Menu from './Menu';

import SECRETS from './secrets';


const styles = (theme) =>  ({
  usuariosScreen: {
    display: 'flex',
    flexDirection: 'row',
  },
  view: {
    width: '100%',
  },
  newView: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  map: {
    height: '85vh',
  },
  actionButton: {
    position: 'fixed',
    bottom: 30,
    right: 30,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  header: {
    backgroundColor: '#333',
  },
  whiteText: {
    color: 'white',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: '80px',
  },
  action: {
    margin: '0 10px',
  },
});


class Usuarios extends Component {
  state = {
    usuarios: [],
  };

  componentDidMount = () => {
    this.getUsuarios();
  };

  getUsuarios = () => {
    const { getCookie } = this.props;
    request({
      method: 'GET',
      uri: `${SECRETS.serverUrl}/Usuario`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        console.log(resp);
        const usuarios = resp.data.map((usuario) => {
          usuario.password = '******';
          return usuario;
        });
        this.setState({ usuarios });
      })
      .catch(err => console.error(err));
  };

  updateUsuario = (index) => {
    const { getCookie } = this.props;
    const { usuarios } = this.state;
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      user,
      password,
      isControl,
      isCivil,
      isPolicia,
      isSuper,
      id,
    } = usuarios[index];
    const attribs = {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      user,
      isControl,
      isCivil,
      isPolicia,
      isSuper,
    };
    if (password !== '******') {
      attribs.password = password;
    }

    request({
      method: 'PATCH',
      uri: `${SECRETS.serverUrl}/usuario/${id}`,
      body: attribs,
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      },
    })
      .then((resp) => {
        usuarios[index].edit = false;
        this.setState({ usuarios });
      })
      .catch(err => console.error(err));
  };

  eliminarUsuario = (id) => () => {
    const { getCookie } = this.props;
    const { usuarios } = this.state;
    request({
      method: 'DELETE',
      uri: `${SECRETS.serverUrl}/usuario/${id}`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const index = usuarios.findIndex(usuario => usuario.id === id);
        usuarios.splice(index, 1);
        this.setState({ usuarios });
      })
      .catch(err => console.error(err));
  };

  activarEdicion = (index) => () => {
    const { usuarios } = this.state;
    usuarios[index].edit = true;
    this.setState({ usuarios });
  };

  guardarEdicion = (index) => () => {
    this.updateUsuario(index);
  };

  handleChange = (name, index) => (ev) => {
    const { usuarios } = this.state;
    usuarios[index][name] = ev.target.value;
    this.setState({ usuarios });
  };

  handleCheck = (name, index) => (ev) => {
    const { usuarios } = this.state;
    usuarios[index][name] = ev.target.checked;
    this.setState({ usuarios });
  };


  render = () => {
    const { classes, getCookie, removeCookie } = this.props;
    const {
      usuarios
    } = this.state;
    if (!getCookie('token')) return <Redirect to="/" />;
    return (
      <div className={classes.usuariosScreen}>
        <Menu removeCookie={removeCookie}/>
        <div className={classes.view}>
          <div>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.header}>
                    <TableCell align="left" className={classes.whiteText}>Usuario</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Contraseña</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Nombres</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Apellido Paterno</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Apellido Materno</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Control</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Policia</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Civil</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Admin</TableCell>
                    <TableCell align="left" className={classes.whiteText}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usuarios.map((usuario, index) => (
                    <TableRow key={usuario.id}>
                      <TableCell component="th" scope="row">
                        <TextField
                          id={`usuario${usuario.id}`}
                          className={classes.textField}
                          value={usuario.user}
                          onChange={this.handleChange('usuario', index)}
                          margin="normal"
                          disabled={!usuario.edit}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextField
                          id={`password${usuario.id}`}
                          className={classes.textField}
                          value={usuario.password}
                          type="password"
                          onChange={this.handleChange('password', index)}
                          margin="normal"
                          disabled={!usuario.edit}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextField
                          id={`nombre${usuario.id}`}
                          className={classes.textField}
                          value={usuario.nombres}
                          onChange={this.handleChange('nombres', index)}
                          margin="normal"
                          disabled={!usuario.edit}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          id={`apellidoP${usuario.id}`}
                          className={classes.textField}
                          value={usuario.apellidoPaterno}
                          onChange={this.handleChange('apellidoPaterno', index)}
                          margin="normal"
                          disabled={!usuario.edit}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          id={`apellidoM${usuario.id}`}
                          className={classes.textField}
                          value={usuario.apellidoMaterno || ''}
                          onChange={this.handleChange('apellidoMaterno', index)}
                          margin="normal"
                          disabled={!usuario.edit}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox
                          id={`control${usuario.id}`}
                          checked={usuario.isControl}
                          className={classes.checkbox}
                          onChange={this.handleCheck('isControl', index)}
                          value="control"
                          disabled
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox
                          id={`policia${usuario.id}`}
                          checked={usuario.isPolicia}
                          className={classes.checkbox}
                          onChange={this.handleCheck('isPolicia', index)}
                          value="policia"
                          disabled
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox
                          id={`civil${usuario.id}`}
                          checked={usuario.isCivil}
                          className={classes.checkbox}
                          onChange={this.handleCheck('isCivil', index)}
                          value="civil"
                          disabled
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox
                          id={`admin${usuario.id}`}
                          checked={usuario.isSuper}
                          className={classes.checkbox}
                          onChange={this.handleCheck('isSuper', index)}
                          value="admin"
                          disabled
                        />
                      </TableCell>
                      <TableCell align="left">
                        {usuario.edit && (
                          <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={this.guardarEdicion(index)}
                          >
                            Guardar
                          </Button>
                        )}
                        {!usuario.edit && (
                          <div className={classes.actions}>
                            <EditIcon className={classes.action} onClick={this.activarEdicion(index)} />
                            <DeleteForever className={classes.action} onClick={this.eliminarUsuario(usuario.id)} />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(Usuarios);
