import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteForever from '@material-ui/icons/DeleteForever';
import request from 'request-promise';

import Menu from '../Menu';
import Map from '../Map';

import SECRETS from '../secrets';


const styles = (theme) =>  ({
  geocercasScreen: {
    display: 'flex',
    flexDirection: 'row',
  },
  view: {
    width: '100%',
  },
  newView: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  map: {
    height: '85vh',
  },
  actionButton: {
    position: 'fixed',
    bottom: 30,
    right: 30,
  },
  fab: {
    margin: theme.spacing.unit,
  },
});

const polygon = [{
  lat: 19.738224813107614,
  lng: -101.19394686767578,
},
{
  lat: 19.681318871863006,
  lng: -101.15544732299804,
},{
  lat: 19.680764340470738,
  lng: -101.22471915405276,
}];

class AdminGeocercas extends Component {
  state = {
    newMode: false,
    nombre: '',
    descripcion: '',
    refMap: '',
    geofences: [{
      uuid: '123-123-213',
      path: polygon,
      active: true,
      edit: true,
    }, ],
    zonas: [],
  };

  componentDidMount = () => {
    this.getZonas();
  };

  getZonas = () => {
    const { getCookie } = this.props;
    request({
      method: 'GET',
      uri: `${SECRETS.serverUrl}/Zona`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        this.setState({ zonas: resp.data });
      })
      .catch(err => console.error(err));
  };

  polygonSet = (index, latlng, position) => {
    const { geofences } = this.state;
    geofences[index].path[position] = latlng;
    this.setState({ geofences });
  };

  pathSet = (index, path) => {
    const { geofences } = this.state;
    geofences[index].path = path;
    this.setState({ geofences });
  }

  guardarZona = () => {
    const { getCookie } = this.props;
    const { nombre, descripcion, geofences } = this.state;
    const poligono = JSON.stringify(geofences[0].path);
    request({
      method: 'POST',
      uri: `${SECRETS.serverUrl}/Zona`,
      body: {
        nombre,
        descripcion,
        poligono,
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const newZona = resp;
        const { zonas } = this.state;
        zonas.push(newZona);
        this.setState({ zonas });
      })
      .catch(err => console.error(err))
      .finally(() => this.setState({ newMode: false }));
  };

  eliminarZona = (id) => () => {
    const { getCookie } = this.props;
    const { zonas } = this.state;
    request({
      method: 'DELETE',
      uri: `${SECRETS.serverUrl}/Zona/${id}`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const index = zonas.findIndex(zona => zona.id === id);
        zonas.splice(index, 1);
        this.setState({ zonas });
      })
      .catch(err => console.error(err));
  };

  toggleNew = () => {
    this.setState({ newMode: true });
  };

  handleChange = name => ev => this.setState({ [name]: ev.target.value });

  onMapMounted = ref => this.setState({ refMap: ref });

  render = () => {
    const { classes, getCookie, removeCookie } = this.props;
    const {
      newMode,
      refMap,
      geofences,
      nombre,
      descripcion,
      zonas,
    } = this.state;
    if (!getCookie('token')) return <Redirect to="/" />;
    return (
      <div className={classes.geocercasScreen}>
        <Menu removeCookie={removeCookie}/>
        <div className={classes.view}>
          {newMode && (
            <div className={classes.newView}>
              <div className={classes.form}>
                <TextField
                  id="nombre"
                  label="Nombre de la Zona"
                  className={classes.textField}
                  value={nombre}
                  onChange={this.handleChange('nombre')}
                  margin="normal"
                />
                <TextField
                  id="descripcion"
                  label="Descripcion"
                  className={classes.textField}
                  value={descripcion}
                  onChange={this.handleChange('descripcion')}
                  margin="normal"
                />
              </div>
              <div className={classes.map}>
                <Map
                  onMapMounted={this.onMapMounted}
                  loadingElement={<div style={{ height: '100%' }} />}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`}
                  containerElement={<div style={{ height: '100%', width: '100%'}} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  geofences={geofences}
                  polygonSet={this.polygonSet}
                  pathSet={this.pathSet}
                  refMap={refMap}
                  toggleEdit={() => {}}
                  policias={[]}
                />
              </div>
              <div className={classes.button}>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={this.guardarZona}
                >
                  Guardar
                </Button>
              </div>
            </div>
          )}
          {!newMode && (
            <div>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell align="right">Descripcion</TableCell>
                      <TableCell align="right">Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {zonas.map(zona => (
                      <TableRow key={zona.id}>
                        <TableCell component="th" scope="row">
                          {zona.nombre}
                        </TableCell>
                        <TableCell align="right">{zona.descripcion}</TableCell>
                        <TableCell align="right"><DeleteForever onClick={this.eliminarZona(zona.id)}/></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div className={classes.actionButton}>
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.toggleNew}>
                  <AddIcon />
                </Fab>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(AdminGeocercas);
