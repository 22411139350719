import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import request from 'request-promise';

import Map from './Map';
import Menu from './Menu';
import SECRETS from './secrets';

const styles = (theme) =>  ({
  MapScreen: {
    display: 'flex',
    flexDirection: 'row',
  },
  map: {
    height: '100vh',
    width: '100vw',
  },
});

class MapScreen extends Component {
  state = {
    refMap: '',
    geofences: [],
    policias: [],
    interval: '',
  };

  componentDidMount = () => {
    // const interval = window.setInterval(this.getPolicias, 1000 * 10);
    this.getZonas();
    this.getPolicias();
    // this.setState({ interval });
  };

  componentWillUnmount = () => {
    const { interval } = this.state;
    window.clearInterval(interval);
  };

  getPolicias = () => {
    const { getCookie } = this.props;
    request({
      method: 'GET',
      uri: `${SECRETS.serverUrl}/policia`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const zona = { nombre: '', id: 0 };
        const policias = resp.data.map((policia) => {
          if (policia.zona === null) {
            policia.zona = zona;
          }
          return policia;
        });
        this.setState({ policias });
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          this.logout();
        }
      });
  };

  getZonas = () => {
    const { getCookie } = this.props;
    request({
      method: 'GET',
      uri: `${SECRETS.serverUrl}/Zona`,
      body: {
      },
      json: true,
      headers: {
        'Authorization': getCookie('token'),
      }
    })
      .then((resp) => {
        const zonas = resp.data;
        const geofences = zonas.map((zona) => {
          return { ...zona, path: JSON.parse(zona.poligono) };
        });
        this.setState({ geofences });
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          this.logout();
        }
      });
  };

  logout = () => {
    const { removeCookie } = this.props;
    removeCookie('token');
    this.setState({ logged: false });
  };

  polygonSet = (index, latlng, position) => {
    const { geofences } = this.state;
    geofences[index].path[position] = latlng;
    this.setState({ geofences });
  };

  pathSet = (index, path) => {
    const { geofences } = this.state;
    geofences[index].path = path;
    this.setState({ geofences });
  }

  toggleEdit = (index) => (ev) => {
    const { getCookie } = this.props;
    const { geofences } = this.state;

    geofences[index].edit = !geofences[index].edit;
    if (!geofences[index].edit) {
      const poligono = JSON.stringify(geofences[index].path);
      request({
        method: 'PATCH',
        uri: `${SECRETS.serverUrl}/Zona/${geofences[index].id}`,
        body: {
          poligono,
        },
        json: true,
        headers: {
          'Authorization': getCookie('token'),
        }
      })
        .then((resp) => {
          console.log('updated');
        })
        .catch((err) => {
          if (err.statusCode === 403) {
            this.logout();
          }
        });
    }
    this.setState({ geofences });
  }

  onMapMounted = ref => this.setState({ refMap: ref });

  render = () => {
    const { classes, getCookie, removeCookie } = this.props;
    const { refMap, geofences, policias } = this.state;
    if (!getCookie('token')) return <Redirect to="/" />;
    return (
      <div className={classes.MapScreen}>
        <Menu removeCookie={removeCookie} />
        <div className={classes.map}>
          <Map
            onMapMounted={this.onMapMounted}
            loadingElement={<div style={{ height: '100%' }} />}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${SECRETS.MAPSAPIKEY}`}
            containerElement={<div style={{ height: '100%', width: '100%'}} />}
            mapElement={<div style={{ height: '100%' }} />}
            geofences={geofences}
            polygonSet={this.polygonSet}
            pathSet={this.pathSet}
            refMap={refMap}
            toggleEdit={this.toggleEdit}
            policias={policias}
          />
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(MapScreen);
