import React from 'react';
import { Marker } from 'react-google-maps';

import marker from './marker-small.png';
import markerOff from './marker-small-off.png';

const CustomMarker = ({
  position,
  info,
  id,
  motor,
  label,
  children,
}) => (
  <Marker
    position={position}
    onClick={() => info(id)}
    icon={{ url: (motor ? marker : markerOff), labelOrigin: { x: 23, y: -5 } }}
    label={{ text: label, color: '#1c3466', fontWeight: 'bold' }}
  >
    {children}
  </Marker>
);

export default CustomMarker;
