import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import request from 'request-promise';

import SECRETS from './secrets';

import logo from './logo.svg';

const styles = (theme) =>  ({
  loginScreen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 400,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 420,
  },
  margin: {
    marginTop: 20,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
});

class Login extends Component {
  state = {
      usuario: '',
      password: '',
      logged: false,
  };

  getLogin = () => {
    const { setCookie } = this.props;
    const { usuario, password } = this.state;
    request({
      method: 'POST',
      uri: `${SECRETS.serverUrl}/api/v1/auth`,
      body: {
          user: usuario,
          password,
      },
      json: true,
    })
      .then((resp) => {
        if (resp.token) {
          setCookie('token', resp.token);
          this.setState({ logged: true });
        }
      })
      .catch(err => console.error(err));
  };

  handleChange = name => ev => this.setState({ [name]: ev.target.value });

  render = () => {
    const { classes, getCookie } = this.props;
    const { usuario, password, logged } = this.state;
    if (logged) return <Redirect to="/map" />;
    if (getCookie('token')) return <Redirect to="/map" />;
    return (
      <div className={classes.loginScreen}>
        <div className={classes.logo}>
          <img src={logo} alt=""/>
        </div>
        <div className={classes.form}>
          <TextField
            id="user"
            label="Usuario"
            className={classes.textField}
            value={usuario}
            onChange={this.handleChange('usuario')}
            margin="normal"
          />
          <TextField
            id="password"
            label="Contrasena"
            type="password"
            className={classes.textField}
            value={password}
            onChange={this.handleChange('password')}
            margin="normal"
          />
          <Button
            size="large"
            color="primary"
            variant="contained"
            className={classes.margin}
            onClick={this.getLogin}
          >
            Entrar
          </Button>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(Login);
